import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Menu from '../components/menu';
import InfoList from '../components/infoList';
import HighLights from '../components/highlights';
import SEO from '../components/SEO';

const Frontpage = ({ pageContext, data, location }) => {
  const { intro, more } = pageContext;

  return (
    <article className="page frontpage">
      <header>
        {/* Intro */}
        <h1>Ferrol</h1>
        {/* <Img fluid={data.image.childImageSharp.fluid} /> */}
        <p className="intro">{intro}</p>
        <Link className="more" to={more}>
          Máis información
        </Link>
        {/* Menu */}
        <nav>
          <Menu className="frontpage" />
        </nav>
        <SEO title="Portada" description={intro} path={location.pathname} />
      </header>

      {/* Destacados */}
      <h2>Destacados</h2>
      <HighLights highlights={data.highlights.edges} className="frontpage" />

      {/* Estáticos */}
      <h2>Máis info</h2>
      <InfoList info={data.info.edges} className="simple" />
    </article>
  );
};

export default Frontpage;

export const query = graphql`
  query($image: String!) {
    info: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/info/" }
        frontmatter: { hidden: { eq: false } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }

    highlights: allMarkdownRemark(
      filter: { frontmatter: { highlight: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            type
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    image: file(absolutePath: { regex: $image }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
